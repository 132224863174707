import React, { useState} from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./resetPassword.css";

const ResetPassword = () => {
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [error, setError] = useState("");

  const reset = async (e) => {
    e.preventDefault();
    if (password === cpassword) {
      setError("");
      await Axios.post(process.env.REACT_APP_API_ENDPOINT + "/resetPassword", {
        password: password,
        token: token,
      })
        .then((res) => {
          toast(res.data.message, { position: "top-center" });
          setTimeout(() => {
            let path = "/";
            history.push(path);
          }, 3000);
        })
        .catch((err) => {
          if (err.response.status === 410) {
            toast(err.response.data.message, { position: "top-center" });
            setTimeout(() => {
              let path = "/";
              history.push(path);
            }, 2000);
          }
        });
    } else {
      setError("Passwords do not match");
    }
  };

  return (
    <div className="justify-content-center mt-5 container resetForm">
      {" "}
      <div className="mt-5 mb-5">
        <h2 className="mt-5">Reset Password</h2>
        <ToastContainer
          hideProgressBar={true}
          draggable
          toastStyle={{ backgroundColor: "#008250", color: "white" }}
        />
      </div>
      <Form
        onSubmit={(e) => {
          reset(e);
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            placeholder="Enter Password"
            className="inputSize"
          />
        </Form.Group>

        <Form.Group className="mt-5 mb-3" controlId="formBasicPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            onChange={(e) => {
              setCpassword(e.target.value);
            }}
            type="password"
            placeholder="Renter new Password"
          />
        </Form.Group>
        <div className="errorMsg">{error}</div>
        <Button className="mt-5 mb-3 submit" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
