import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./childAlert.css";

function ChildAlert() {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  const getForm = () => {
    let path = "childForm";
    history.push(path);
    history.go(0);
  };
  const submit = () => {
    confirmAlert({
      overlayClassName: "childAlert",
      closeOnEscape: false,
      closeOnClickOutside: false,
      title: "Add a child to continue",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            getForm();
          },
        },
        {
          label: "Later",
          onClick: () => {
            history.push("/myaccount");
            history.go(0);
          },
        },
      ],
    });
  };
  useEffect(() => {
    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return token ? (
    <div></div>
  ) : (
    <Container fluid style={{ marginTop: "10rem" }}>
      <div>
        <h2>Unauthorised</h2>
      </div>
    </Container>
  );
}

export default ChildAlert;
