/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { LogOutButton } from "./NavComponents";
import "../css/Navigation.css";

const Navigation = (props) => {
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem("authorisation"));
  }, []);

  return (
    <>
      <header>
        <Navbar
          collapseOnSelect
          expand="false"
          fixed="top"
          className="navbar"
          variant="light"
        >
          <Navbar.Brand className="mx-5" href="https://techfunic.com">
            <img
              alt=""
              src="/img/techfunic-logo.webp"
              width="150"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">{token && <LogOutButton />}</Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
};

export default withRouter(Navigation);
