import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPasswordModal = () => {
  const history = useHistory();
  const [femail, setFemail] = useState({ email: "" });
  // eslint-disable-next-line no-unused-vars
  const [fpassword, setFpassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showp, setShowp] = useState(true);
  const handleClosep = () => {
    setShowp(false);
    setFpassword(false);
    setFemail({ ...femail, email: "" });
    history.push("/login");
  };

  const handleShowp = () => setShowp(true);

  const forgotPassword = (e) => {
    e.preventDefault();
    console.log("Email" + femail.email);
    Axios.post(process.env.REACT_APP_API_ENDPOINT + "/forgotPassword", {
      email: femail.email,
    })
      .then((res) => {
        console.log(res);
        toast(res.data.message, { position: "top-center" });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          toast(err.response.data.message, { position: "top-center" });
        }
      });
    setFpassword(true);
  };

  return (
    <Modal show={handleShowp} onHide={handleClosep} animation={true}>
      <Modal.Header closeButton>
        <ToastContainer
          hideProgressBar={true}
          draggable
          toastStyle={{ backgroundColor: "#008250", color: "white" }}
        />
      </Modal.Header>

      <Container>
        <Row className="justify-content-center">
          <h2 className="mt-5">Enter Registered Email</h2>
        </Row>

        <Row>
          <Col> </Col>
          <Col md={6}>
            <Form
              onSubmit={(e) => {
                forgotPassword(e);
              }}
            >
              <Form.Group className="my-4" controlId="femail">
                <Form.Control
                  required
                  className="register"
                  type="email"
                  placeholder="Email*"
                  onChange={(e) => {
                    setFemail({ ...femail, email: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group className="text-center" controlId="pass">
                <Button
                  // onClick={forgotPassword}

                  className="registerButton my-3 center"
                  variant="primary"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Form.Group>
            </Form>

            <Row className="justify-content-center mt-3">
              <Button variant="secondary" onClick={handleClosep}>
                Cancel
              </Button>
            </Row>
          </Col>
          <Col> </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ForgotPasswordModal;
