/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Button, Form, Col, Card } from "react-bootstrap";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddChild from "../AddChild";

import "./childSelection.css";

export const ChildSelection = () => {
  const history = useHistory();
  const location = useLocation();

  const [count, setCount] = useState(0);
  const [isCreate, setIsCreate] = useState(true);
  const [childFname, setChildFname] = useState("");
  const [childLname, setChildLname] = useState("");
  const [childEmail, setChildEmail] = useState("");
  const [childDOB, setChildDOB] = useState("");
  const [childAge, setChildAge] = useState();
  const [childGrade, setChildGrade] = useState("");
  const [editChild, setEditChild] = useState(false);
  const [childData, setchildData] = useState([]);
  const [childDetails, setChildDetails] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  let [token, setToken] = useState(localStorage.getItem("authorisation"));

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const getChildren = () => {
    try {
      Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: { authorisation: "Bearer " + token },
      }).then((res) => {
        const data = res.data;
        setchildData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
   
    getChildren();
    if (!token) {
      history.push("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add = (e) => {
    e.preventDefault();
    Axios.post(
      process.env.REACT_APP_API_ENDPOINT + "/children",
      {
        firstName: childFname,
        lastName: childLname,
        email: childEmail,
        age: getAge(childDOB),
        dob: childDOB,
        grade: childGrade,
      },
      { headers: { authorisation: "Bearer " + token } }
    )
      .then((res) => {
        toast("Child added successfully", { position: "top-center" });
        setIsCreate(true);
        seterrorMsg("");
        getChildren();
      })
      .catch((err) => {
        if (err) {
          seterrorMsg(err.request.response);
        }
      });
  };

  const select = (e) => {
    e.preventDefault();
    let path = "/purchases/qanda";
    console.log("here");
    // const serviceId = location.state.serviceId;
    const serviceId = localStorage.getItem("service");
    history.push({
      pathname: path,
      state: {
        count: count,
        details: childDetails,
        // serviceId: location.state.serviceId,
        serviceId: localStorage.getItem("service"),
      },
    });
  };

  return token ? (
    <div className="mt-7">
      <ToastContainer
        hideProgressBar={true}
        draggable
        toastStyle={{ backgroundColor: "#008250", color: "white" }}
      />
      <Container>
        {/* forms */}
        {isCreate ? (
          <div></div>
        ) : (
          <AddChild
            isOpen={!isCreate}
            submitHandler={add}
            closeModal={() => {
              setIsCreate(true);
              seterrorMsg("");
            }}
            childFname={childFname}
            setChildFname={setChildFname}
            childLname={childLname}
            setChildLname={setChildLname}
            childEmail={childEmail}
            setChildEmail={setChildEmail}
            childDOB={childDOB}
            setChildDOB={setChildDOB}
            childGrade={childGrade}
            setChildGrade={setChildGrade}
            errorMsg={errorMsg}
          />
        )}
        {/* results */}
        {childData.length > 0 ? (
          <>
            <Row className="d-flex justify-content-center align-items-center childHeading">
              {count} {count > 0 ? "children" : "child"} selected
            </Row>
            <Row className="mt-4 childrenContainer">
              <Form>
                <div className="childContainer">
                  {childData.map((child) => (
                    <>
                      <input
                        className="childCheckbox"
                        name="group1"
                        type="checkbox"
                        id={child.id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setChildDetails([
                              ...childDetails,
                              {
                                id: child.id,
                                fname: child.firstName,
                                lname: child.lastName,
                                email: child.email,
                                grade: child.grade,
                                age: child.age,
                              },
                            ]);
                            setCount(count + 1);
                          } else {
                            setChildDetails((oldChildDetails) =>
                              oldChildDetails.filter((c) => c.id !== child.id)
                            );
                            setCount(count - 1);
                          }
                        }}
                      />
                      <label
                        key={child.id}
                        className="childCard"
                        for={child.id}
                      >
                        <h2>
                          {child.firstName} {child.lastName}
                        </h2>
                        <p>Grade: {child.grade}</p>
                        <p>{child.email}</p>
                      </label>
                    </>
                  ))}
                </div>
                {/* <Button type="submit" className="submit">
                  Purchase
                </Button> */}
              </Form>
            </Row>
          </>
        ) : (
          <div></div>
        )}
        <Row className="my-4 d-flex justify-content-center align-items-center">
          <button
            className="childButton"
            type="button"
            variant="primary"
            onClick={() => {
              setIsCreate(false);
            }}
          >
            Add children
          </button>
        </Row>
        <Row className="my-4 d-flex justify-content-center align-items-center">
          <button
            className="childButton proceedMenuButton"
            disabled={count < 1}
            onClick={select}
            type="button"
          >
            {count <= 0 ? "Next" : "Proceed"}
          </button>
        </Row>
      </Container>
    </div>
  ) : (
    <Container fluid style={{ marginTop: "10rem" }}>
      <div></div>
    </Container>
  );
};

export default ChildSelection;
