import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import Axios from "axios";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./loginPage.css";

const LoginModal = () => {
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  let priceId = "";
  // eslint-disable-next-line no-unused-vars
  const [show, setshow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  let [Token, setToken] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const handleClose = () => {
    setlogMsg("");
  };

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const [logMsg, setlogMsg] = useState("");

  const getChildren = async () => {
    try {
      await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: {
          authorisation: "Bearer " + localStorage.getItem("authorisation"),
        },
      }).then((res) => {
        const data = res.data;
        if (!localStorage.getItem("plan")) {
          if (data.length > 0) {
            let path = "/myaccount";
            history.push(path);
            history.go(0);
          } else {
            let path = "/childalert";
            history.push(path);
            // history.go(0);
          }
        } else {
          history.push({
            pathname: `/purchase/${localStorage.getItem("service")}`,
            state: {
              // serviceId: location.state.serviceId,
              serviceId: localStorage.getItem("service"),
            },
          });
          history.go(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    await Axios.post(process.env.REACT_APP_API_ENDPOINT + "/login", {
      email: userLogin.email,
      password: userLogin.password,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);

        handleClose();
        getChildren();
      })
      .catch((err) => {
        <div className="text-center">{logMsg}</div>;
        if (err) {
          setlogMsg(err.request.response);
        }
      });
  };
  const responseGoogle = (response) => {
    Axios.post(process.env.REACT_APP_API_ENDPOINT + "/signup/google", {
      googleId: response.googleId,
      tokenId: response.tokenId,
      method: "Google",
      userTimeZone: selectedTimezone,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);

        getChildren();
      })
      .catch((err) => {
        if (err) {
          setlogMsg(err.request.response);
        }
      });
  };

  const responseFacebook = (response) => {
    Axios.post(process.env.REACT_APP_API_ENDPOINT + "/signup/facebook", {
      facebookId: response.userID,
      accessToken: response.accessToken,
      method: "Facebook",
      userTimeZone: selectedTimezone,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);
        getChildren();
      })
      .catch((err) => {
        if (err) {
          console.log(err.request);
          setlogMsg(err.request.response);
        }
      });
  };
  const errGoogle = (respsonse) => {
    console.log(respsonse);
  };
  return (
    <Container className="text-center fluid mt-3">
      <div className="mt-7 flexbox">
        <Card className="border-0 cards mt-5" style={{ width: "80%" }}>
          <Card.Body className="cardBody">
            <img
              alt=""
              src="/img/techfunic-logo.webp"
              width="150"
              height="40"
              className="d-inline-block align-top"
            />
            <Row className="justify-content-center mt-5 ">
              <h2
                className="text-center"
                style={{ font: "Roboto", fontWeight: "400", fontSize: "16px" }}
              >
                Welcome
              </h2>
            </Row>
            <Row className="justify-content-center mt-3 ">
              <h2
                className="text-center"
                style={{ font: "Roboto", fontWeight: "700", fontSize: "30px" }}
              >
                Login to your account
              </h2>
            </Row>

            <Row>
              <Col> </Col>
              <Col md={6}>
                <Form
                  onSubmit={(e) => {
                    login(e);
                  }}
                >
                  <Form.Group className="my-4" controlId="email">
                    <Form.Label
                      style={{
                        float: "left",
                        font: "Roboto",
                        fontWeight: "400",
                        fontSize: "17px",
                      }}
                    >
                      E-mail*
                    </Form.Label>
                    <Form.Control
                      required
                      className="inputs"
                      type="email"
                      placeholder="Email*"
                      onChange={(e) => {
                        setUserLogin({ ...userLogin, email: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="my-4" controlId="pass">
                    <Form.Label
                      style={{
                        float: "left",
                        font: "Roboto",
                        fontWeight: "400",
                        fontSize: "17px",
                      }}
                    >
                      Password*
                    </Form.Label>

                    <Form.Control
                      required
                      className="inputs"
                      type="password"
                      placeholder="Password*"
                      onChange={(e) => {
                        setUserLogin({
                          ...userLogin,
                          password: e.target.value,
                        });
                      }}
                    />
                    {/* <span>  <FaEye/></span> */}
                  </Form.Group>

                  <div
                    style={{ textAlign: "center" }}
                    className="passwordResetLink"
                  >
                    <a
                      // eslint-disable-next-line no-script-url
                      href="/forgotpassword"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                    >
                      Forgot Password ?
                    </a>
                  </div>

                  <Form.Group className="text-center" controlId="pass">
                    <Button className="loginButton my-3 center" type="submit">
                      Login
                    </Button>
                  </Form.Group>
                  <div className="text-center errorMsg mb-3">{logMsg}</div>
                </Form>
                <div className="mt-3 text-center facebook-div">
                  <FacebookLogin
                    appId="934878953821790"
                    fields="name,email,picture"
                    scope="public_profile, email"
                    autoLoad={false}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <FacebookLoginButton
                        text={"Continue with Facebook"}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="facebook-btn"
                      />
                    )}
                  />
                </div>

                <div className="mt-4 mb-4 google-div">
                  <GoogleLogin
                    clientId="47111505422-539hb8npfj5tkrd3k9p62cbhf0v0rqc2.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={errGoogle}
                    autoLoad={false}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <GoogleLoginButton
                        text={"Continue with Google"}
                        className="google-btn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col> </Col>
            </Row>
            <Row className="justify-content-center">
              <p style={{ fontSize: "17px" }}>
                Don't have an account?{" "}
                <a
                  // eslint-disable-next-line no-script-url
                  href="/signup"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (localStorage.getItem("plan")) {
                      history.push({
                        pathname: "/signup",
                        state: {
                          priceId: location.state.priceId,
                        },
                      });
                    } else {
                      history.push("/signup");
                    }
                  }}
                >
                  <strong> Sign Up</strong>
                </a>
              </p>
            </Row>
            {/* </div> */}
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default LoginModal;
