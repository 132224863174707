import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "32px",
    padding: "50px",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const ModalWrapper = ({ isOpen, children }) => (
  <Modal isOpen={isOpen} style={customStyles} contentLabel="Example Modal">
    {children}
  </Modal>
);

export default ModalWrapper;
