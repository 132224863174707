/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import { Container, Button, Row } from "react-bootstrap";
import IndexComponents from "./IndexComponents";
// import { ArenguForm } from "react-arengu-forms"
export default function Form() {
  const location = useLocation();
  const history = useHistory();
  // const priceId = location.state.priceId;
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  const [activeChild, setActiveChild] = useState(location.state.details[0]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [childCount, setChildCount] = useState();
  const child = [];
  const [test, setTest] = useState([]);
  const [question, setQuestion] = useState([]);
  const [response, setResponse] = useState({});
  const [disabled, isDisabled] = useState(true);

  useEffect(() => {
    if (!token) {
      history.push("/login");
    } else {
      setChildCount(location.state.count);
      try {
        Axios.get(process.env.REACT_APP_API_ENDPOINT + "/myaccount", {
          headers: { authorisation: "Bearer " + token },
        }).then((res) => {
          const data = res.data;
          const { id, firstName, lastName, email, phone, iat } = data;
          setId(id);
          setFirstName(firstName);
          setLastName(lastName);
          setEmail(email);
          setPhone(phone);
          const x = location.state.details; //child details
          const defaultResponse = {};
          location.state.details.forEach((c) => (defaultResponse[c.id] = {}));
          setResponse(defaultResponse);
          console.log(x);
          setTest(x);
        });
      } catch (error) {
        console.log(error);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) {
      history.push("/login");
    } else {
      let id;
      // if (!localStorage.getItem("plan")) {
      //   id = localStorage.getItem("service");
      // } else {
      //   id = localStorage.getItem("service");
      // }
      id = localStorage.getItem("service");
      console.log("y " + id);

      try {
        Axios.post(process.env.REACT_APP_API_ENDPOINT + "/services/details", {
          id: id,
        }).then((res) => {
          const service = res.data;
          console.log(service);
          try {
            Axios.post(
              process.env.REACT_APP_API_ENDPOINT + "/services/questions",
              {
                id: id,
              }
            ).then((res) => {
              const questions = res.data;
              console.log(questions);
              setQuestion(questions);
            });
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailsToStripe = async () => {
    const parentId = id;
    console.log("Parent Id : " + parentId);
    //service id
    const service_id = location.state.serviceId;
    console.log("Service Id  : " + service_id);
    //count of children
    const count = childCount;
    console.log("Count of children : " + count);
    //child array which includes child id and name , for fetching child id
    const array = test;
    console.log("child array");
    console.log(array);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/checkout`,
        {},
        {
          headers: { authorisation: "Bearer " + token },
        }
      );
      console.log(data);
      window.location.replace(data.url);
    } catch (err) {
      console.log(err);
    }
  };

  const select = async (e) => {
    e.preventDefault();
    let path = "/purchases/review";
    // const serviceId = location.state.serviceId;
    const serviceId = localStorage.getItem("service");
    console.log(response);
    try {
      history.push({
        pathname: path,
        state: {
          count: childCount,
          details: test,
          // serviceId: location.state.serviceId,
          serviceId: serviceId,
          question,
          response,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container
      fluid="sm"
      className="d-flex flex-column align-items-center mt-7"
    >
      <Row className="d-flex flex childTab">
        {test.map((t) => (
          <div
            className={`childPill ${activeChild.id === t.id && "pillActive"}`}
            onClick={() => setActiveChild(t)}
          >
            {t.fname}
          </div>
        ))}
      </Row>
      <IndexComponents
        children={activeChild}
        questions={question}
        updateResponse={setResponse}
        response={response}
        disabled={isDisabled}
      />
      <Row className="my-4 d-flex justify-content-center align-items-center">
        <button
          className="childButton backMenuButton"
          onClick={() =>
            // history.push({ pathname: `/purchase/${location.state.serviceId}` })
            history.push({
              pathname: `/purchase/${localStorage.getItem("service")}`,
            })
          }
        >
          &lt;&lt; Back
        </button>
        <button
          disabled={disabled}
          className="childButton proceedMenuButton"
          type="button"
          onClick={select}
        >
          Next
        </button>
      </Row>
      {/* <Button onClick={detailsToStripe}>Stripe</Button> */}
    </Container>
  );
}
