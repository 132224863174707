/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Row, Button, Form, Col } from "react-bootstrap";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AddChild from "../AddChild";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const ChildForm = () => {
  const history = useHistory();

  const [isCreate, setIsCreate] = useState(true);
  const [errorMsg, seterrorMsg] = useState("");
  const [childFname, setChildFname] = useState("");
  const [childLname, setChildLname] = useState("");
  const [childEmail, setChildEmail] = useState("");
  const [childAge, setChildAge] = useState();
  const [childGrade, setChildGrade] = useState("");
  const [childDOB, setChildDOB] = useState("");
  const [editChild, setEditChild] = useState(false);
  const [childData, setchildData] = useState([]);
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  const [isChild, setIschild] = useState(false);
  const getChildren = () => {
    try {
      Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: { authorisation: "Bearer " + token },
      }).then((res) => {
        const data = res.data;

        setchildData(data);

        if (data.length > 0) {
          setIschild(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add = (e) => {
    e.preventDefault();
    Axios.post(
      process.env.REACT_APP_API_ENDPOINT + "/children",
      {
        firstName: childFname,
        lastName: childLname,
        email: childEmail,
        age: getAge(childDOB),
        dob: childDOB,
        grade: childGrade,
      },
      { headers: { authorisation: "Bearer " + token } }
    )
      .then((res) => {
        toast("Child added successfully", { position: "top-center" });
        seterrorMsg("");

        setIsCreate(true);
        setTimeout(() => {
          let path = "/myaccount";
          history.push(path);
          history.go(0);
        }, 2000);
      })
      .catch((err) => {
        seterrorMsg(err.request.response);
      });
  };

  return token ? (
    <div className="mt-7">
      <ToastContainer
        hideProgressBar={true}
        draggable
        toastStyle={{ backgroundColor: "#008250", color: "white" }}
      />
      <Container>
        <div>
          {!isChild ? (
            <AddChild
              isOpen={true}
              submitHandler={add}
              closeModal={() => {
                seterrorMsg("");
                history.push("/myaccount");
              }}
              childFname={childFname}
              setChildFname={setChildFname}
              childLname={childLname}
              setChildLname={setChildLname}
              childEmail={childEmail}
              setChildEmail={setChildEmail}
              childDOB={childDOB}
              setChildDOB={setChildDOB}
              childGrade={childGrade}
              setChildGrade={setChildGrade}
              errorMsg={errorMsg}
            />
          ) : (
            <Container fluid style={{ marginTop: "10rem" }}>
              <div>
                <h2>not allowed</h2>
              </div>
            </Container>
          )}
        </div>
      </Container>
    </div>
  ) : (
    <Container fluid style={{ marginTop: "10rem" }}>
      <div>
        <h2>Unauthorised</h2>
      </div>
    </Container>
  );
};

export default ChildForm;
