/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import { Container, Button, Row } from "react-bootstrap";
import IndexComponents from "./IndexComponents";
import "./parentDetails.css";
// import { ArenguForm } from "react-arengu-forms"
export default function ArenguForm() {
  const location = useLocation();
  const history = useHistory();
  // const priceId = location.state.priceId;
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [activeChild, setActiveChild] = useState(location.state.details[0]);
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [childCount, setChildCount] = useState();
  const child = [];
  const [test, setTest] = useState([]);
  const [price_id, setPriceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [paymentType, setpaymentType] = useState("");

  useEffect(() => {
    // console.log("In Arengu == " + location.state.serviceId);
    if (!token) {
      history.push("/login");
    } else {
      setChildCount(location.state.count);
      // const y = location.state.serviceId;
      const y = localStorage.getItem("service");
      try {
        Axios.get(process.env.REACT_APP_API_ENDPOINT + "/myaccount", {
          headers: { authorisation: "Bearer " + token },
        }).then((res) => {
          const data = res.data;
          console.log(data);
          const { id, firstName, lastName, email, phone, iat } = data;
          setId(id);
          setFirstName(firstName);
          setLastName(lastName);
          setEmail(email);
          setPhone(phone);
          const x = location.state.details;
          setTest(x);
          let Serviceid;
          // if (!localStorage.getItem("plan")) {
          //   // Serviceid = location.state.serviceId;
          //   Serviceid = localStorage.getItem("service");
          // } else {
          //   Serviceid = localStorage.getItem("service");
          // }
          Serviceid = localStorage.getItem("service");
          try {
            Axios.post(
              process.env.REACT_APP_API_ENDPOINT + "/services/details",
              {
                id: Serviceid,
              }
            ).then((res) => {
              const service = res.data;
              console.log(service);
              setpaymentType(service.paymentType);
              setServiceName(service.name);
              console.log(service.priceId);
              setPriceId(service.priceId);
              const script = document.createElement("script");
              script.id = "form";
              script.src = "https://sdk.arengu.com/forms.js";
              script.async = true;
              document.body.appendChild(script);
            });
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceedPayment = async () => {
    console.log("In Arengu == " + location.state.serviceId);
    try {
      const { data: transactionObject } = await Axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/transaction",
        {
          response: location.state.response,
          // serviceId: location.state.serviceId,
          serviceId: localStorage.getItem("service"),
        },
        { headers: { authorisation: "Bearer " + token } }
      );

      const { data: checkoutSession } = await Axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/checkout`,
        {
          transactionId: transactionObject.transactionId,
          childCount: location.state.count,
          // serviceId: location.state.serviceId,
          serviceId: localStorage.getItem("service"),
        },
        {
          headers: { authorisation: "Bearer " + token },
        }
      );
      window.location.replace(checkoutSession.url);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center flex-column parentDetails">
        <div className="d-flex flex-column p-6 justify-content-center">
          <div>
            <h3 className="font-weight-bold parentHeading mt-4">
              Parent & Child Details
            </h3>
          </div>
          <div className="mt-2 d-flex justify-content-center flex-column align-content-center">
            <p>
              <span className="detailLabel">Parent Name:</span> {firstName}{" "}
              {lastName}
            </p>
            <p>
              <span className="detailLabel">Email:</span> {Email}
            </p>
            <p>
              <span className="detailLabel">Phone:</span> {Phone}
            </p>
          </div>
          <div>
            <p>
              <span className="detailLabel">Chosen Program:</span> {serviceName}
            </p>
          </div>
          {console.log(test)}
          {test.map((child, i) => (
            <div className="my-4">
              <p>
                <span className="detailLabel">Child {i + 1} Name:</span>{" "}
                {child.fname} {child.lname}
              </p>
              <p>
                <span className="detailLabel">Email:</span> {child.email}
              </p>
              <p>
                <span className="detailLabel">Grade:</span> {child.age}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center flex-column mt-4 parentDetails">
        <h3>Q&A about the child</h3>
        <Row className="d-flex childTab justify-content-center">
          {test.map((t) => (
            <div
              className={`childPill ${activeChild.id === t.id && "pillActive"}`}
              onClick={() => setActiveChild(t)}
            >
              {t.fname}
            </div>
          ))}
        </Row>
        <div className="mt-4">
          {location.state.question.map((q) => (
            <div key={q.id}>
              <p className="questionText">{q.text}</p>
              <p>{location.state.response[activeChild.id][q.id]}</p>
            </div>
          ))}
        </div>

        <button
          className="childButton proceedMenuButton"
          onClick={proceedPayment}
        >
          Proceed to Payment
        </button>
      </div>
    </>
  );
}
