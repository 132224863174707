import React from "react";
import ModalWrapper from "../Modal";
import { Row, Form } from "react-bootstrap";

const AddChild = ({
  isOpen,
  submitHandler,
  closeModal,
  childFname,
  setChildFname,
  childLname,
  setChildLname,
  childEmail,
  setChildEmail,
  childDOB,
  setChildDOB,
  childGrade,
  setChildGrade,
  errorMsg,
}) => (
  <ModalWrapper isOpen={isOpen}>
    <Row>
      <h2
        className="text-center"
        style={{
          fontWeight: "bold",
          fontSize: "30px",
          lineHeight: "37px",
        }}
      >
        Add a child
      </h2>
    </Row>
    <Row className="d-flex flex-column">
      <Form onSubmit={submitHandler}>
        <Form.Group className="mt-4">
          <p
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#2D3748",
              marginBottom: "8px",
              paddingLeft: "8px",
            }}
          >
            First Name
          </p>
          <Form.Control
            required
            className="inputs"
            onChange={(e) => setChildFname(e.target.value)}
            value={childFname}
            type="text"
            placeholder="FirstName"
          />
        </Form.Group>
        <Form.Group>
          <p
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#2D3748",
              marginBottom: "8px",
              paddingLeft: "8px",
            }}
          >
            Last Name
          </p>
          <Form.Control
            required
            className="inputs"
            onChange={(e) => setChildLname(e.target.value)}
            value={childLname}
            type="text"
            placeholder="Last Name"
          />
        </Form.Group>
        <Form.Group>
          <p
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#2D3748",
              marginBottom: "8px",
              paddingLeft: "8px",
            }}
          >
            Email
          </p>
          <Form.Control
            required
            className="inputs"
            onChange={(e) => setChildEmail(e.target.value)}
            value={childEmail}
            type="email"
            placeholder="Email"
          />
        </Form.Group>
        <Form.Group>
          <p
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#2D3748",
              marginBottom: "8px",
              paddingLeft: "8px",
            }}
          >
            Date of Birth
          </p>
          <Form.Control
            required
            className="inputs"
            onChange={(e) => {
              setChildDOB(e.target.value);
            }}
            value={childDOB}
            type="date"
            placeholder="dd/mm/yyyy"
          />
        </Form.Group>
        <Form.Group>
          <p
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#2D3748",
              marginBottom: "8px",
              paddingLeft: "8px",
            }}
          >
            Grade
          </p>
          <Form.Control
            required
            className="inputs"
            onChange={(e) => setChildGrade(e.target.value)}
            value={childGrade}
            type="number"
            placeholder="Grade"
          />
        </Form.Group>

        <div className="text-center errorMsg mt-3 mb-3">{errorMsg}</div>
        <Form.Group>
          <button className="childButton proceedMenuButton" type="submit">
            Add Child
          </button>
          <button className="childButton backMenuButton" onClick={closeModal}>
            Cancel
          </button>
        </Form.Group>
      </Form>
    </Row>
  </ModalWrapper>
);

export default AddChild;
