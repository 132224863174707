import React from "react";
import { Button } from "react-bootstrap";
import "./NotFound.css";

const NotFound = () => {
  
  
  return (
    <div className="not-found">
      <span>
        <h1 className="not-found-heading">OOPS!</h1>
        <div className="not-found-msg">
          We can't find the page you're looking for.<br/> Reach out to us for help at{' '}
              <a href="mailto:team@arithfunic.com">team@arithfunic.com</a> or
              call us at <a href="tel:+1 201 238 5627">+1 201 238 5627</a> 
        </div>
        <div>
          <Button href="/" className="not-found-btn">
            Home
          </Button>
        </div>
      </span>
    </div>
  );
};

export default NotFound;
