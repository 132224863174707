import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Axios from "axios";

const Purchase = () => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  // eslint-disable-next-line no-unused-vars
  const [childData, setchildData] = useState([]);
  const { serviceId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const getChildren = () => {
    try {
      Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: { authorisation: "Bearer " + token },
      }).then((res) => {
        const data = res.data;
        setchildData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(
    () => {
      if (!token) {
        localStorage.setItem("plan", "yes");
        localStorage.setItem("service", serviceId);
        history.push({
          pathname: "/login",
          state: {
            serviceId: serviceId,
          },
        });
      } else {
        localStorage.setItem("service", serviceId);

        history.push({
          pathname: "/purchases/childselection",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return <div></div>;
};

export default Purchase;
