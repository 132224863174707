// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navigation/Navigation';
import { Route, useHistory } from 'react-router-dom';
import MyAccount from './components/MyAccount/Account';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ChildAlert from './components/ChildAlert/childAlert';
import ChildForm from './components/ChildForm/ChildForm';
import ChildSelection from './components/ChildSelectPlan/ChildSelection';
import SignUpModal from './components/SignUp/SignUpModal';
import LoginModal from './components/Login/LoginModal';
import ForgotPasswordModal from './components/Login/ForgotPasswordModal';
import Purchase from './components/Purchase/purchase';
import ArenguForm from './components/Form/Arengu';
import NotFound from './components/NotFound/NotFound';
import { Switch } from 'react-router';
import Form from './components/Form';

function App() {
  const history = useHistory();

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Route path="/" component={Navbar} />
      <Route
        exact
        path="/"
        component={() => {
          history.push('/myaccount');
          return <></>;
        }}
      />
      <Switch>
        <Route exact path="/signup" component={SignUpModal} />
        <Route exact path="/login" component={LoginModal} />
        <Route exact path="/forgotpassword" component={ForgotPasswordModal} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/forgotpassword" component={ForgotPasswordModal} />

        {/* Custom Navbar */}
        <Route exact path="/childalert" component={ChildAlert} />
        <Route exact path="/childForm" component={ChildForm} />
        
        <Route exact path="/purchases/qanda">
          <Form />
        </Route>
        <Route exact path="/purchases/review" component={ArenguForm} />
        <Route exact path="/purchases/childselection" component={ChildSelection} />
        <Route exact path="/purchase/:serviceId" component={Purchase} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
