/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Container, Row, Button, Form, Col, Card } from "react-bootstrap";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const AddChildren = (props) => {
  const [isCreate, setIsCreate] = useState(true);
  const [childId, setChildId] = useState();
  const [childFname, setChildFname] = useState("");
  const [childLname, setChildLname] = useState("");
  const [childEmail, setChildEmail] = useState("");
  // const [childAge, setChildAge] = useState();
  const [childDOB, setChildDOB] = useState();
  const [childGrade, setChildGrade] = useState("");
  const [editChild, setEditChild] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  let [token, setToken] = useState(localStorage.getItem("authorisation"));

  const children = props.data;

  const add = () => {
    Axios.post(
      process.env.REACT_APP_API_ENDPOINT + "/children",
      {
        firstName: childFname,
        lastName: childLname,
        email: childEmail,
        age: getAge(childDOB),
        dob: childDOB,
        grade: childGrade,
      },
      { headers: { authorisation: "Bearer " + token } }
    )
      .then((res) => {
        toast("Child added successfully", { position: "top-center" });
        seterrorMsg("");
        setIsCreate(true);
        props.getChildren();
      })
      .catch((err) => {
        if (err) {
          seterrorMsg(err.request.response);
        }
      });
  };

  const update = () => {
    Axios.put(
      process.env.REACT_APP_API_ENDPOINT + "/children",
      {
        id: childId,
        firstName: childFname,
        lastName: childLname,
        email: childEmail,
        age: getAge(childDOB),
        dob: childDOB,
        grade: childGrade,
      },
      { headers: { authorisation: "Bearer " + token } }
    )
      .then((res) => {
        toast("Edited successfully", { position: "top-center" });
        setEditChild(false);
        setIsCreate(true);
        props.getChildren();
      })
      .catch((err) => {
        if (err) {
          console.log(err.request.response);
        }
      });
  };

  const edit = (id, fname, lname, email, dob, grade) => {
    setChildFname(fname);
    setChildLname(lname);
    setChildEmail(email);
    setChildDOB(dob);
    setChildGrade(grade);
    setChildId(id);
    setIsCreate(false);
    setEditChild(true);
  };

  return token ? (
    <div className="mt-7">
      <ToastContainer
        hideProgressBar={true}
        draggable
        toastStyle={{ backgroundColor: "#008250", color: "white" }}
      />
      <Container>
        <Button
          className={
            "ml-4 " + (isCreate ? "d-inline-block" : "d-none") + " submit"
          }
          variant="primary"
          type="submit"
          onClick={() => {
            setIsCreate(false);
          }}
        >
          Add children
        </Button>
        {/* forms */}
        {isCreate ? (
          <div></div>
        ) : (
          <div>
            <Form
              className="form-slot"
              onSubmit={(e) => {
                e.preventDefault();
                if (editChild === true) {
                  update();
                } else {
                  add();
                }
              }}
            >
              {" "}
              <Row>
                <Col></Col>
                <Col lg={12}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      required
                      type="text"
                      placeholder="First Name"
                      value={childFname}
                      onChange={(e) => {
                        setChildFname(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last Name"
                      value={childLname}
                      onChange={(e) => {
                        setChildLname(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      required
                      type="email"
                      value={childEmail}
                      placeholder="Email address"
                      onChange={(e) => {
                        setChildEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      required
                      type="date"
                      value={
                        editChild
                          ? new Date(childDOB).toISOString().substr(0, 10)
                          : childDOB
                      }
                      placeholder="dd/mm/yyyy"
                      onChange={(e) => {
                        setChildDOB(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      value={childGrade}
                      type="text"
                      placeholder="Grade"
                      required
                      onChange={(e) => {
                        setChildGrade(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Row>
                    <Button className="text-center submit" type="submit">
                      Submit
                    </Button>
                    <Button
                      className="text-center ml-3"
                      variant="danger"
                      onClick={() => {
                        setChildFname("");
                        setChildLname("");
                        setChildEmail("");
                        setChildGrade("");
                        setChildDOB("");
                        seterrorMsg("");
                        setIsCreate(true);
                        setEditChild(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Row>
                </Col>

                <Col></Col>
              </Row>
              <div className="text-center errorMsg mt-3 mb-3">{errorMsg}</div>
            </Form>
          </div>
        )}
        {/* results */}
        {children.length > 0 ? (
          <Row className="mt-5">
            {children.map((child) => {
              return (
                <div key={child.id}>
                  <Col md={3}>
                    <Card className="m-3" style={{ width: "18rem" }}>
                      <Card.Body>
                        <Card.Subtitle className="mb-2">
                          {/* <h4>{props.slotDescription}</h4>
              <h5>
               
                {props.startDate.split("T")[0]} -- {props.endDate.split("T")[0]}
              </h5> */}
                        </Card.Subtitle>
                        <Card.Text>First Name -- {child.firstName}</Card.Text>
                        <Card.Text>Last Name - {child.lastName}</Card.Text>
                        <Card.Text>Email - {child.email}</Card.Text>
                        <Card.Text>Age- {child.age}</Card.Text>
                        <Card.Text>Grade - {child.grade}</Card.Text>
                        <Button
                          className="submit"
                          variant="primary"
                          onClick={() => {
                            edit(
                              child.id,
                              child.firstName,
                              child.lastName,
                              child.email,
                              child.dob,
                              child.grade
                            );
                          }}
                        >
                          Edit
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              );
            })}
          </Row>
        ) : (
          <div></div>
        )}
      </Container>
    </div>
  ) : (
    <Container fluid style={{ marginTop: "10rem" }}>
      <div>
        <h2>Unauthorised</h2>
      </div>
    </Container>
  );
};

export default AddChildren;
