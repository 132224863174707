import React from "react";
import { Form } from "react-bootstrap";
import "./questions.css";

const IndexComponents = (props) => {
  // eslint-disable-next-line no-unused-vars
  const datas = props.children;
  const questions = props.questions;
  const updateResponse = props.updateResponse;
  const response = props.response;
  const disabled = props.disabled;

  const handleResponse = (e, qId) => {

    updateResponse((oldResponse) => {
      let value = e.target.value;
      if (!oldResponse[datas.id]) {
        oldResponse[datas.id] = {};
      }

      if (e.target.type === "checkbox") {
        if (!oldResponse[datas.id][qId]) {
          oldResponse[datas.id][qId] = [];
        }
        const answerSet = new Set(oldResponse[datas.id][qId]);

        if (e.target.checked) {
          answerSet.add(e.target.value);
        } else {
          answerSet.delete(e.target.value);
        }

        value = [...answerSet];
      }

      const newResponse = {
        ...oldResponse,
        [datas.id]: {
          ...oldResponse[datas.id],
          [qId]: value,
        },
      };
      console.log(newResponse);
      disabled(false);
      return newResponse;
    });
  };

  return (
    <div className="mt-4 questionContainer" key={datas.id}>
      <div>
        {questions.map((question) => (
          <Form>
            <div key={question.id}>
              <p className="questionText">{question.text}</p>
              {question.type === "string" ? (
                <div>
                  {" "}
                  <Form.Group controlId="email">
                    <Form.Control
                      required
                      className="authInput"
                      type="text"
                      value={
                        response &&
                        response[datas.id] &&
                        response[datas.id][question.id]
                          ? response[datas.id][question.id]
                          : ""
                      }
                      onChange={(e) => handleResponse(e, question.id)}
                    />
                  </Form.Group>
                </div>
              ) : question.type === "multi-select" ? (
                <div>
                  <p>
                    {" "}
                    {JSON.parse(question.answer).map((q) => (
                      <Form.Check
                        label={q}
                        name="group1"
                        type="checkbox"
                        value={q}
                        checked={
                          response &&
                          response[datas.id] &&
                          response[datas.id][question.id] &&
                          response[datas.id][question.id].includes(q)
                        }
                        onChange={(e) => handleResponse(e, question.id)}
                      />
                    ))}
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    {JSON.parse(question.answer).map((q) => (
                      <Form.Check
                        label={q}
                        name="group2"
                        type="radio"
                        value={q}
                        checked={
                          response &&
                          response[datas.id] &&
                          response[datas.id][question.id] === q
                        }
                        onChange={(e) => handleResponse(e, question.id)}
                      />
                    ))}
                  </p>
                </div>
              )}
            </div>
          </Form>
        ))}
      </div>
    </div>
  );
};

export default IndexComponents;
