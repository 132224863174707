import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import TimezoneSelect from "react-timezone-select";
import Axios from "axios";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./signup.css";
var generator = require("generate-password");

const SignUpModal = () => {
  const history = useHistory();
  // const location = useLocation();

  // if generate password is clicked
  // let [autoPasswordForm, setAutoPasswordForm] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let [Token, setToken] = useState("");
  const [userRegister, setUserRegister] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    cpassword: "",
    phone: "",
    code: "",
    phoneValue: "",
  });
  // showing modal

  const [regMsg, setRegMsg] = useState("");
  const [logMsg, setlogMsg] = useState("");

  const handleCloses = () => {
    setRegMsg("");
    // setAutoPasswordForm(false);
  };

  const timezones = {
    "America/Chicago": "Central Time",
    "America/Los_Angeles": "Pacific Time",
    "America/Boise": "Mountain Time",
    "America/New_York": "Eastern Time",
    "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
  };

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const getChildren = async () => {
    try {
      await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: {
          authorisation: "Bearer " + localStorage.getItem("authorisation"),
        },
      }).then((res) => {
        const data = res.data;
        if (!localStorage.getItem("plan")) {
          if (data.length > 0) {
            let path = "/myaccount";
            history.push(path);
            history.go(0);
          } else {
            let path = "/childalert";
            history.push(path);
            // history.go(0);
          }
        } else {
          history.push({
            pathname: `/purchase/${localStorage.getItem("service")}`,
            state: {
              serviceId: localStorage.getItem("service"),
            },
          });
          history.go(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //function for loggging the user just after registration
  const logins = async () => {
    await Axios.post(process.env.REACT_APP_API_ENDPOINT + "/login", {
      email: userRegister.email,
      password: userRegister.password,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);

        handleCloses();
        getChildren();
      })
      .catch((err) => {
        <div className="text-center">{logMsg}</div>;
        if (err) {
          setlogMsg(err.request.response);
        }
      });
  };

  // eslint-disable-next-line no-unused-vars
  const generatePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
    });

    // setAutoPasswordForm((autoPasswordForm = true));
    setUserRegister({
      ...userRegister,
      password: password,
    });
  };

  const register = (e) => {
    e.preventDefault();
    if (userRegister.cpassword === userRegister.password) {
      userRegister.code = userRegister.phoneValue.substring(
        0,
        userRegister.phoneValue.length - 10
      );
      userRegister.phone = userRegister.phoneValue.substring(
        userRegister.phoneValue.length - 10,
        userRegister.phoneValue.length
      );
      if (userRegister.phone === "") {
        userRegister.phone = null;
        userRegister.code = null;
      }

      Axios.post(process.env.REACT_APP_API_ENDPOINT + "/signup", {
        firstName: userRegister.firstname,
        lastName: userRegister.lastname,
        email: userRegister.email,
        password: userRegister.password,
        code: userRegister.code,
        phone: userRegister.phone,
        method: "Native",
        userTimeZone: selectedTimezone.value
          ? selectedTimezone.value
          : selectedTimezone,
      })
        .then((res) => {
          toast("Registration Successfull", { position: "top-center" });
          logins();
        })
        .catch((err) => {
          if (err) {
            setRegMsg(err.request.response);
          }
        });
    } else {
      setRegMsg("Passwords do not match");
    }
  };

  const responseGoogle = (response) => {
    Axios.post(process.env.REACT_APP_API_ENDPOINT + "/signup/google", {
      googleId: response.googleId,
      tokenId: response.tokenId,
      method: "Google",
      userTimeZone: selectedTimezone,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);

        handleCloses();
        getChildren();
      })
      .catch((err) => {
        if (err) {
          setRegMsg(err.request.response);
        }
      });
  };

  const responseFacebook = (response) => {
    Axios.post(process.env.REACT_APP_API_ENDPOINT + "/signup/facebook", {
      facebookId: response.userID,
      accessToken: response.accessToken,
      method: "Facebook",
      userTimeZone: selectedTimezone,
    })
      .then((res) => {
        const token = res.data["authorisation"];
        setToken(token);
        localStorage.setItem("authorisation", token);

        handleCloses();
        getChildren();
      })
      .catch((err) => {
        if (err) {
          console.log(err.request);
          setRegMsg(err.request.response);
        }
      });
  };
  const errGoogle = (respsonse) => {
    console.log(respsonse);
  };
  return (
    <Container className="mb-7">
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        draggable
        toastStyle={{ backgroundColor: "#008250", color: "white" }}
      />
      <div className="mt-7 flexbox">
        <Card className="border-0 cards mt-5 mb-5 signupCard">
          <Card.Body className="cardBody">
            <img
              alt=""
              src="/img/techfunic-logo.webp"
              width="150"
              height="40"
              className="d-inline-block align-top"
            />
            <Row className="justify-content-center">
              <h2 className="mt-5 welcome">Welcome</h2>
            </Row>
            <Row className="justify-content-center">
              <h2 className="create">Create your account</h2>
            </Row>

            <Row className="mt-4">
              <Col>
                <Form
                  onSubmit={(e) => {
                    register(e);
                  }}
                >
                  <div className="row1">
                    <div>
                      <Form.Group className="my-2" controlId="firstname">
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          First Name
                        </Form.Label>
                        <Form.Control
                          className="inputs"
                          required
                          type="text"
                          placeholder="First Name*"
                          onChange={(e) => {
                            setUserRegister({
                              ...userRegister,
                              firstname: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group className="my-4" controlId="lastname">
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="inputs"
                          required
                          type="text"
                          placeholder="Last Name*"
                          onChange={(e) => {
                            setUserRegister({
                              ...userRegister,
                              lastname: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row1">
                    <div>
                      <Form.Group className="my-2" controlId="email">
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          E-mail
                        </Form.Label>
                        <Form.Control
                          required
                          className="inputs"
                          type="email"
                          placeholder="E-mail*"
                          onChange={(e) => {
                            setUserRegister({
                              ...userRegister,
                              email: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="my-4"
                      >
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          Phone Number
                        </Form.Label>
                        <PhoneInput
                          required={true}
                          placeholder="Phone*"
                          value={userRegister.phoneValue}
                          enableSearch={true}
                          country={"us"}
                          inputClass="phone-input"
                          // inputClass="inputs mt-5"
                          inputStyle={{
                            width: "100%",
                            fontSize: "1rem",
                            fontFamily: "Montserrat",
                            color: "#495057",
                            border: "1px solid #ced4da",
                            borderRadius: "25px",
                            transition:
                              "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                          }}
                          onChange={async (e) => {
                            setUserRegister({
                              ...userRegister,
                              phoneValue: e,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group className="mb-3 form-group-slot">
                    <Form.Label className="form-label-slot mt-3">
                      Choose your Timezone
                    </Form.Label>
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                      timezones={timezones}
                      required
                    />
                  </Form.Group>

                  {/* {autoPasswordForm ? (
                    <Form.Group className="my-4" controlId="pass">
                      <Form.Control
                        required
                        value={userRegister.password}
                        type="text"
                        placeholder="Password*"
                        onChange={(e) => {
                          setUserRegister({
                            ...userRegister,
                            password: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  ) : ( */}
                  <div className="row2">
                    <div>
                      <Form.Group className="my-4" controlId="pass">
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          Password
                        </Form.Label>
                        <Form.Control
                          className="inputs"
                          required
                          type="password"
                          placeholder="Password*"
                          onChange={(e) => {
                            setUserRegister({
                              ...userRegister,
                              password: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group className="my-4" controlId="pass">
                        <Form.Label
                          style={{
                            float: "left",
                            font: "Roboto",
                            fontWeight: "400",
                            fontSize: "17px",
                          }}
                        >
                          Confirm Passsword
                        </Form.Label>
                        <Form.Control
                          required
                          className="inputs"
                          type="password"
                          placeholder="Confirm Password*"
                          onChange={(e) => {
                            setUserRegister({
                              ...userRegister,
                              cpassword: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {/* )} */}
                  <Form.Group className="text-center" controlId="pass">
                    {/* <Button
                  onClick={generatePassword}
                  className="generatePasswordButton my-3 center"
                  type="button"
                >
                  Generate Password
                </Button> */}
                  </Form.Group>
                  <Form.Group className="text-center" controlId="pass">
                    <Button
                      className="loginButton my-3 center"
                      variant="primary"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </Form.Group>
                  <div className="text-center errorMsg mt-1 mb-3">{regMsg}</div>
                </Form>
                <div className="mt-3 text-center facebook-div">
                  <FacebookLogin
                    appId="934878953821790"
                    fields="name,email,picture"
                    scope="public_profile, email"
                    autoLoad={false}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <FacebookLoginButton
                        text={"Signup with Facebook"}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="facebook-btn"
                      />
                    )}
                  />
                </div>

                <div className="mt-4 google-div">
                  <GoogleLogin
                    clientId="47111505422-539hb8npfj5tkrd3k9p62cbhf0v0rqc2.apps.googleusercontent.com"
                    // buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={errGoogle}
                    autoLoad={false}
                  
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <GoogleLoginButton
                        className="google-btn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        text={"Signup with Google"}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <p className="already">
                Already have an account?{" "}
                <a
                  // eslint-disable-next-line no-script-url
                  href="/login"
                  style={{ color: "#045184", textDecoration: "none" }}
                  onClick={() => {}}
                >
                  Log in
                </a>
              </p>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default SignUpModal;
