/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  Col,
  Button,
  Row,
  Image,
  Container,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  useAccordionToggle,
} from "react-bootstrap";
import "./account.css";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddChildren from "./AddChild";
import { useBootstrapPrefix } from "react-bootstrap/esm/ThemeProvider";
const MyAccount = () => {
  const history = useHistory();
  let [token, setToken] = useState(localStorage.getItem("authorisation"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [update, setUpdate] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [childData, setchildData] = useState([]);
  const [method, setMethod] = useState("");
  //for getting user information
  useEffect(() => {
    // async function fetchData() {
    // await
    if (!localStorage.getItem("authorisation")) {
      history.push("/login");
    } else {
      try {
        Axios.get(process.env.REACT_APP_API_ENDPOINT + "/myaccount", {
          headers: { authorisation: "Bearer " + token },
        }).then((res) => {
          const data = res.data;
          const { id, firstName, lastName, email, phone, method, iat } = data;
          setId(id);
          setFirstName(firstName);
          setLastName(lastName);
          setEmail(email);
          setPhone(phone);
          setMethod(method);
        });
      } catch (error) {
        console.log(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChildren = () => {
    try {
      Axios.get(process.env.REACT_APP_API_ENDPOINT + "/children", {
        headers: { authorisation: "Bearer " + token },
      }).then((res) => {
        const data = res.data;

        setchildData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //for getting child information
  useEffect(() => {
    getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notify = () => toast("Profile Updated", { position: "top-center" });

  const changePassword = () => {
    let path = "/changePassword";
    history.push(path);
  };

  const updateUser = async (e) => {
    await Axios.put(process.env.REACT_APP_API_ENDPOINT + "/updateuser", {
      id: id,
      firstName: firstName,
      lastName: lastName,
      email: Email,
      phone: Phone,
    }).then((res) => {
      const token = res.data["authorisation"];
      localStorage.setItem("authorisation", token);
      notify();
    });
  };

  const editProfileName = () => {
    setEditProfile(true);
  };
  const cancelEditProfile = () => {
    setEditProfile(false);
  };

  useEffect(() => {}, [Phone]);

  useEffect(() => {}, [Email]);

  useEffect(() => {}, [firstName]);

  useEffect(() => {}, [lastName]);

  const updatePhone = (e) => {
    setPhone(e.target.value);
  };

  return (
    <Container>
      <Card className="myAccountCard text-center" border="light">
        <ToastContainer
          hideProgressBar={true}
          draggable
          toastStyle={{ backgroundColor: "#008250", color: "white" }}
        />
        <Card.Header className="profileHolder">
          <Container fluid>
            <Row>
              <Col xs={1} className="mt-3">
                <OverlayTrigger
                  key="right"
                  placement="right"
                  overlay={<Tooltip id="tooltip-right">Change Photo</Tooltip>}
                >
                  <svg
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ cursor: "pointer" }}
                    className="cameraSvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="14"
                    viewBox="0 0 19 14"
                  >
                    {" "}
                    <path
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      d="M0 3a1 1 0 0 1 1-1h3.75L5.418.576A1 1 0 0 1 6.323 0h6.354a1 1 0 0 1 .905.576L14.25 2H18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3zm9.5 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm0-1a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"
                    />{" "}
                  </svg>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row className="mt-5 mb-5"></Row>
            {!editProfile ? (
              <Row className="mt-5">
                <Col>
                  <div className="d-flex justify-content-start">
                    <Row>
                      <Col>
                        <Image
                          src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          height="100"
                          width="100"
                          roundedCircle
                        />
                      </Col>
                      <Col className="profileHolderUsername ml-3 my-5">
                        {firstName + " " + lastName}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex justify-content-end">
                    <Row>
                      <Col className="my-5">
                        {" "}
                        <Button className="publicProfileButton">
                          Make profile public
                        </Button>
                      </Col>
                      <Col className="ml-1 my-5">
                        <Dropdown>
                          <Dropdown.Toggle
                            syle={{ color: "white" }}
                            className="toggleButton"
                          >
                            <svg
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="15.406"
                              viewBox="0 0 4 15.406"
                              class="_2ZqjF"
                              style={{ fillRule: "evenodd", cursor: "pointer" }}
                            >
                              <path
                                d="M1000,381.719a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,381.719Zm0-5.78a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,375.939Zm0,7.706a1.928,1.928,0,1,1-2,1.927A1.968,1.968,0,0,1,1000,383.645Z"
                                transform="translate(-998 -372.094)"
                                fill="#FFFFFF"
                              ></path>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              id="dropdown-item"
                              href="javascript:;"
                              onClick={editProfileName}
                            >
                              Edit Profile
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>{" "}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className="d-flex justify-content-between mt-5">
                <Col lg={6}>
                  <div className="d-flex justify-content-start">
                    <Row>
                      <Col lg={6}>
                        <Image
                          src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          height="100"
                          width="100"
                          roundedCircle
                        />
                      </Col>
                      <Col className="my-5">
                        <Form.Control
                          fluid
                          className="profileHolderEditProfileName"
                          value={firstName + " " + lastName}
                          onChange={(e) => {
                            var name = e.target.value.split(" ");
                            setFirstName(name[0]);
                            setLastName(name[1]);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="d-flex justify-content-end">
                    <Row>
                      <Col className="my-5">
                        <Button
                          className="publicProfileButton"
                          onClick={cancelEditProfile}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col className="my-5">
                        <Button
                          onClick={() => {
                            //once the name the is split in db implement this
                          }}
                          className="profileHolderSaveButton"
                          style={{ background: "white", color: "black" }}
                        >
                          Save
                        </Button>
                      </Col>
                      <Col className=" my-5">
                        <svg
                          onClick={null}
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="15.406"
                          viewBox="0 0 4 15.406"
                          class="_2ZqjF"
                          style={{ fillRule: "evenodd", cursor: "pointer" }}
                        >
                          <path
                            d="M1000,381.719a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,381.719Zm0-5.78a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,375.939Zm0,7.706a1.928,1.928,0,1,1-2,1.927A1.968,1.968,0,0,1,1000,383.645Z"
                            transform="translate(-998 -372.094)"
                            fill="#FFFFFF"
                          ></path>
                        </svg>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </Card.Header>
        <Card.Body>
          <Card.Title></Card.Title>
          <Card.Text>
            <Row className="mt-3 mb-3">
              <Col style={{ fontSize: "26px", fontStyle: "normal" }}>
                My Account
              </Col>
            </Row>
            <Row className="mt-3 mb-5">
              <Col
                style={{
                  fontSize: "15px",
                  fontStyle: "normal",
                  color: "#303030",
                }}
              >
                View and edit your personal info below.
              </Col>
            </Row>
            <Row>
              <Col>
                <hr style={{ color: "#303030" }}></hr>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>Account</Col>
            </Row>
            <Row>
              <Col style={{ fontSize: "15px", color: "#303030" }}>
                Update &amp; Edit the information you share with the community
              </Col>
            </Row>
            <Row>
              <AddChildren data={childData} getChildren={getChildren} />
            </Row>
            <Row className="mt-5">
              <Col style={{ fontSize: "15px", color: "#303030" }}>
                Login Email:
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: "15px", color: "#303030" }}>{Email}</Col>
            </Row>
            {method === "Local" ? (
              <div>
                <Row className="mb-5">
                  <Col style={{ fontSize: "15px", color: "#30303080" }}>
                    Your Login email can't be changed
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col style={{ fontSize: "15px", color: "#303030" }}>
                    Change Password :
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    {" "}
                    <Button
                      style={{ background: "#4b5e7e", border: "white" }}
                      onClick={changePassword}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div></div>
            )}
            <Form>
              <Form.Row>
                <Col>
                  <label
                    style={{
                      fontSize: "15px",
                      color: "#303030",
                      fontFamily: "avenir-lt-w01_35-light1475496, sans-serif",
                      fontWeight: "normal",
                    }}
                  >
                    First Name
                  </label>
                  <Form.Control
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <label
                    style={{
                      fontSize: "15px",
                      color: "#303030",
                      fontFamily: "avenir-lt-w01_35-light1475496, sans-serif",
                      fontWeight: "normal",
                    }}
                  >
                    Last Name
                  </label>
                  <Form.Control
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Col>
                  <label
                    style={{
                      fontSize: "15px",
                      color: "#303030",
                      fontFamily: "avenir-lt-w01_35-light1475496, sans-serif",
                      fontWeight: "normal",
                    }}
                  >
                    Email
                  </label>
                  <Form.Control
                    placeholder="Email"
                    defaultValue={Email}
                    disabled
                  />
                </Col>
                <Col>
                  <label
                    style={{
                      fontSize: "15px",
                      color: "#303030",
                      fontFamily: "avenir-lt-w01_35-light1475496, sans-serif",
                      fontWeight: "normal",
                    }}
                  >
                    Phone
                  </label>
                  <Form.Control
                    placeholder="Phone"
                    value={Phone}
                    onChange={(e) => {
                      updatePhone(e);
                    }}
                  />
                </Col>
              </Form.Row>
            </Form>
            <br />
            <div>
              <Button
                variant="secondary"
                className="float-right mr-2"
                onClick={() => {
                  window.location.reload(true);
                }}
              >
                Discard
              </Button>

              <Button
                variant="secondary"
                className="float-right mr-2"
                onClick={(e) => {
                  updateUser(e);
                }}
              >
                Update
              </Button>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MyAccount;
